import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const clientMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Sent Inventory listing",
        path: PATH_DASHBOARD.general.inventorySentListing,
      },
      {
        title: "Sent Inventory Graph",
        path: PATH_DASHBOARD.general.graphSent,
      },
      {
        title: "Received Inventory listing",
        path: PATH_DASHBOARD.general.inventoryReceivedListing,
      },
      {
        title: "Live Stock",
        path: PATH_DASHBOARD.general.liveFacilityStockListing,
      },

    ],
  },
];

export { clientMenu   };
